<template>
    <v-col cols="12">
        <base-card>

            <v-card-title>{{ $t('admin.reports.accessToSystemHabits.title') }}</v-card-title>

            <v-card-text
                class="mb-0 pb-0"
                style="min-height: 431px"
            >
                <apexchart
                    v-if="loaded"
                    type="heatmap"
                    width="100%"
                    height="400"
                    :options="chartOptions"
                    :series="series"
                />
            </v-card-text>

            <p
                class="text-center mb-3 mt-0 pb-3 pb-md-6 mx-2 text-small"
                v-text="$t('admin.reports.accessToSystemHabits.description')"
            />
        </base-card>
    </v-col>
</template>

<script>
import axios from '@/plugins/axios'

export default {
    name: "AccessHabits",
    props:{
        companyId: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            loaded: false,
            series: [
                {
                    name: 'Sun',
                    data: []
                },
                {
                    name: 'Mon',
                    data: []
                },
                {
                    name: 'Tue',
                    data: []
                },
                {
                    name: 'Wed',
                    data: []
                },
                {
                    name: 'Thu',
                    data: []
                },
                {
                    name: 'Fri',
                    data: []
                },
                {
                    name: 'Sat',
                    data: []
                }
            ],
            chartOptions: {
                chart: {
                    height: 400,
                    type: 'heatmap',
                    toolbar: {
                        show: false
                    }
                },
                grid: {
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    }
                },
                plotOptions: {
                    heatmap: {
                        radius: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                colors: ['#2D99FF'],
                xaxis: {
                    categories: [
                        '12am',
                        '1am',
                        '2am',
                        '3am',
                        '4am',
                        '5am',
                        '6am',
                        '7am',
                        '8am',
                        '9am',
                        '10am',
                        '11am',
                        '12pm',
                        '1pm',
                        '2pm',
                        '3pm',
                        '4pm',
                        '5pm',
                        '6pm',
                        '7pm',
                        '8pm',
                        '9pm',
                        '10pm',
                        '11pm'
                    ],
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                }
            }
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                const accessHabits = await axios
                    .get('reports/accessHabits/'+this.companyId);

                const days = accessHabits.data.accessHabits;

                days.forEach((dayValues, index) => {
                    this.series[index].data = dayValues;
                })

                this.loaded = true;
                // this.jmPieChartOne.series = [this.numberHelper.percent(purposesCloudPublished.data.count, purposesCloudPublished.data.usersCount, 0)];
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.error) // "invalid_credentials"
                    console.log(error.response.status)
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
